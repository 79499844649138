<template>
  <div>
    <div v-if="isshowgrid">
      <va-card title="Service List">
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container" :value="term" placeholder="search" @input="search" removable>
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 mt-2 text-right">
            <va-button icon="fa fa-plus" class="my-0" @click.prevent="add()">
              Create
            </va-button>
          </div>
        </div>
        <va-data-table :fields="fields" :data="filteredData" :per-page="parseInt(perPage)" style="max-height: 90vh;">
          <template slot="actions" slot-scope="props">
            <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0">
            </va-button>
          </template>
          <template slot="action" slot-scope="props">
            <va-button flat small color="red" icon="fa fa-remove" @click="remove(props.rowData)" class="ma-0">
            </va-button>
          </template>
        </va-data-table>
        <va-modal v-model="showRemoveModal" title="Delete Vendor" size='small' :message="msg" okText="Confirm"
          cancelText="Cancel" @ok="deleteService(entity)" @cancel="cancel()">
        </va-modal>
      </va-card>
    </div>
    <div v-if="isshowForm">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div class="mt-2">
          <form>
            <span class="va-form-label va-form-required-label">Name</span>
            <va-input
              v-if="isCreate"
              v-model="name"
              :options="ServiceNameArr"
              @input="searchService()"
              :error="!!NameErrors.length"
              :error-messages="NameErrors"
            />
            <div class="results" v-if="listOpen">
              <ul>
                <li v-for="name in ServiceNameArr" @click="onClick(name)">
                  <span>{{name.name}}</span>
                </li>
              </ul>
            </div>
            <va-input 
              v-model.trim="name" 
              type="text" 
              v-if="isUpdate" 
              placeholder="Enter Name"
              :error="!!NameErrors.length" 
              :error-messages="NameErrors" 
            />
            <div>
              <span class="va-form-label va-form-required-label">Image (306 X 170)</span>
              <div>
                <va-file-upload v-if="isCreate || isUpdate" type="single" class="mt-2 ml-1" @input="CheckFile('horizontal')"
                  v-model="image_file" />
                <div v-if="isCreate && getImage" class="ml-1 mt-1"><img :src=image_url height="250px" width="250px" /></div>
                <div v-if="isUpdate">
                  <div class="scroll_overflow">
                    <div class="mt-2 ml-1" v-if="oldImage">
                      <div><img :src=image_url height="250px" width="250px" /></div>
                    </div>
                  </div>
                </div>
                <div v-if="((isCreate && !getImage) || isUpdate) && imageFile" class="imagePreviewWrapper mt-1 ml-1"
                  :style="{ 'background-image': `url(${previewImage})` }">
                </div>
              </div>
            </div>
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel
              </va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createService()">
                Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateService()">
                Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

  import Vue from 'vue'
  import vueResource from 'vue-resource'
  import { search } from '../../i18n/search.js'
  import config from '../../i18n/en.json'
  import { debounce } from 'lodash'
  Vue.use(vueResource)

  export default {
    name: 'app_setting',
    beforeCreate() {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/service').then(response => {
        loader.hide()
        this.service_list = response.body
        this.isshowgrid = true
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      });
    },
    data() {
      return {
        term: null,
        perPage: '10',
        perPageOptions: ['4', '6', '10', '20'],
        service_list: [],
        name: '',
        NameErrors: [],
        image_file: '',
        previewImage: null,
        img: '',
        service_term: '',
        ServiceTermErrors: [],
        oldImage: false,
        isshowgrid: true,
        isshowForm: false,
        isCreate: false,
        isUpdate: false,
        showRemoveModal: false,
        msg: '',
        searchCreate: false,
        getImage: false,
        listOpen: false,
        imageFile: false,
        backup_service: ''
      }
    },
    computed: {
      formReady() {
        return !this.NameErrors.length
      },
      fields() {
        return [{
          name: 'type',
          title: 'Type',
        },
        {
          name: 'name',
          title: 'Name',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        },
        {
          name: '__slot:action',
          dataClass: 'text-right',
        }]
      },
      filteredData() {
        return search(this.term, this.service_list)
      },
    },
    methods: {
      getService() {
        const loader = Vue.$loading.show({ width: 40, height: 40 });
        this.$http.get(config.menu.host + '/service').then(response => {
          loader.hide();
          this.service_list = response.body;
          this.isshowgrid = true;
        }, error => {
          loader.hide();
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          };
        });
      },
      getInstanceService(name) {
        if(name){
          var filter = this.ServiceNameArr.filter(function(instant){
            return instant.name == name
          })
          if(filter.length>0){
            this.getImage = true;
            this.name = filter[0].name;
            this.image_url = filter[0].image;
          }else{
            this.searchCreate=false
          }
        }
      },
      CheckFile(type) {
        if (this.image_file[0] == undefined || this.image_file.length == 0) {
          this.previewImage = null
        }
        if (typeof (type) !== 'undefined') {
          var image_file;
          if (type == 'horizontal') {
              image_file = this.image_file[this.image_file.length - 1]          }
          if ((type == 'horizontal') && (image_file !== undefined)) {
            var file_name = image_file.name.split('.').pop()
            if ((file_name.toLowerCase() === 'png') || (file_name.toLowerCase() === 'jpg') || (file_name.toLowerCase() === 'jpeg')) {
              const img = new Image()
              let width = '306'
              let height = '170'
              this.image_name = image_file.name
              const reader = new FileReader()
              reader.readAsDataURL(image_file)
              reader.onload = evt => {
                if (type == 'horizontal') {
                  this.imageFile = true
                  this.previewImage = evt.target.result
                  this.oldImage = false
                  this.getImage = false;
                }
                img.onload = () => {
                  if (img.width == width && img.height == height) {
                    if (type == 'horizontal') {
                      this.image_name = image_file.name
                    }
                  } else {
                    if (type === 'horizontal') {
                      this.image_file = ''
                    }
                    var flag = this.image_file ? 'horizontal' : 'banner'
                    if (flag === 'horizontal') {
                      this.image_file = []
                    }
                    if (this.image_file == '') {
                      this.previewImage = null
                    }
                    Vue.notify({ text: 'Please check the image size', type: 'error' })
                  }
                }
                img.src = evt.target.result
              }
            } else {
              if (type === 'horizontal') {
                this.image_file = ''
                Vue.notify({ text: 'Please check the image Format', type: 'error' })
              }
            }
          }
        }
      },
      createService() {
        this.NameErrors = this.name ? [] : ['Name is required'];
        if(!this.getImage){
          if (!this.formReady) { return };
        }

        var arrName = [];
        this.service_list.map(function(data){arrName.push(data.name)})            
        if(arrName.includes(this.name)){
          return Vue.notify({text:'The given food name already exists', type: 'error'});
        }

        var Service = 'service'
        var payload = new FormData()

        if(this.getImage){
          payload.append('image', this.image_url);
        }else{
          payload.append('image_file', this.image_file[0]);
          payload.append('image_file_name', this.image_name);
        }
        payload.append('name', this.name);
        payload.append('type', Service); 

        const loader = Vue.$loading.show({ width: 40, height: 40 });
        this.$http.post(config.menu.host + '/service', payload).then(responseData => {
          loader.hide();
          Vue.notify({ text: responseData.body, type: 'success' });
          this.list();
        }, err => {
          loader.hide();
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          };
        });
      },
      updateService() {
        this.NameErrors = this.name ? [] : ['Name is required'];
        if (!this.formReady) { return };

        var arrName = [], vm = this;
        this.service_list.map(function(data){
          if(vm.backup_service != data.name){
            arrName.push(data.name)
          };
        });            
        if(arrName.includes(this.name)){
          return Vue.notify({text:'The given service name already exists', type: 'error'});
        };

        
        var Service = 'service'
        var payload = new FormData()
        if(this.image_file[0]){
          payload.append('image_file', this.image_file[0]);
          payload.append('image_file_name', this.image_name);
        };
        payload.append('name', this.name);
        payload.append('type', Service);

        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.put(config.menu.host + '/service/' + this.service_id, payload).then(responseData => {
          loader.hide();
          Vue.notify({ text: responseData.body, type: 'success' });
          this.list();
        }, err => {
          loader.hide();
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          };
        });
      },
      edit(row) {
        this.title = 'Update Service';
        this.service_id = row.service_id;
        this.name = row.name;
        this.backup_service = this.name;
        this.NameErrors = [];
        this.image_file = '';
        this.image_url = row.image;
        if(row.image) this.imageFile = false;
        this.previewImage = '';
        this.isshowgrid = false;
        this.isshowForm = true;
        this.isCreate = false;
        this.isUpdate = true;
        this.oldImage = true;
        this.ServiceNameArr = [];
      },
      remove(row) {
        this.msg = 'Are you sure to delete the service ' + row.name + ' ?';
        this.entity = row;
        this.showRemoveModal = true;
      },
      cancel() {
        this.showRemoveModal = false;
      },
      deleteService(data) {
        this.$http.delete(config.menu.host + '/service/' + data.service_id).then(resp => {
          Vue.notify({ text: resp.body, type: 'success' });
          this.list();
        }, err => {
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          };
        });
      },
      add() {
        this.title = 'Create Service';
        this.name = '';
        this.NameErrors = [];
        this.image_file = [];
        this.previewImage = '';
        this.isshowForm = true;
        this.isshowgrid = false;
        this.isUpdate = false;
        this.isCreate = true;
        this.getImage = false;
        this.ServiceNameArr = [];
      },
      list() {
        this.getService();
        this.isshowgrid = true;
        this.isshowForm = false;
      },
      search: function (term) {
        this.term = term;
      },
      onClick(data) {
        this.name = data.name;
        this.listOpen = false;
        this.searchCreate = true;
        this.getInstanceService(data.name)
      },
      searchService : _.debounce(function () {
        var payload = { service_term : this.name };
        this.$http.post(config.menu.host + '/service/search', payload).then(response => {
          this.listOpen = true;
          this.ServiceNameArr = response.body;
        })
      }),
    },
  }
</script>

<style>
.results ul {
  width: 50%;
  padding: 6px;
}

.results li {
  background-color: rgba(221, 221, 221, 0.5);
  padding: 5px;
  display: flex;
  align-items: center;
}

.searchbox {
  text-align: left;
}

.searchbox input {
  height: 30px;
  font-size: 16px;
  padding: 0 10px;
}

.results li:hover {
  background-color: rgba(200, 194, 197, 0.8);
  cursor: pointer;
}

.results li span {
  display: inline-block;
}
</style>
